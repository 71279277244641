@charset "utf-8";
/* CSS Document */

body {
  background-color: white;
  font-family: "Hoefler Text", "Georgia", "serif";
  text-align: center;
  font-size: 1em;
  line-height: 1.3;
  width: 92%;
  max-width: 777px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
}

h1 {
  font-size: 1.7em;
}

p {
  margin: auto;

  small {
    font-size: 0.86em;
  }
}

.sentencecase {
  text-transform: none;
}

.validation-messages {
  a:link, a:visited, a:active {
    color: darkred;
  }

  a:hover {
    color: red;
  }
}

#id_order_type {
  list-style: none;
  margin: 0;
  padding: 0;
}

#id_order_type input {
  display: none;
}

#id_order_type label {
  display: block;
  padding: 10px 20px 13px;
  border: 1px solid black;
  margin-bottom: 8px;

}

.radio.selected {
  background-color: lightgrey;
}

input[type=checkbox] {
  display: none;
}

.fancy-check {
  height: 1em;
  width: 1em;
  display: inline-block;
  border: 1px solid black;
  vertical-align: text-bottom;
}

.fancy-check.checked:before {
  content: "x";
  margin-left: 1px;

}

mark {
  background-color: inherit;
  color: red;
}

.form-item {
  padding-bottom: 9px;

  .sold-out {
    text-decoration: line-through;
  }

}

.contact-head {
  margin-bottom: 12px;
  text-decoration: underline;
}

table {

  border-collapse: collapse;
  margin: auto;

  th {
    font-weight: normal;
    text-transform: uppercase;
  }

  td, th {
    border: 1px solid black;
    padding: 7px 12px 9px;
  }
}

#paypal-button {
  width: 58%;
  margin: auto;
}

.clear {
  clear: both;
}

#payment-form {
  #paypal-button {
    margin-bottom: 12px;
  }

  > p, > label, .spacer {
    margin-bottom: 9px;
  }

  #btcc_fields {
    width: 80%;
    margin: auto;

    .half-col {
      float: left;
      width: 48.5%;
      display: inline;
      margin: auto;
    }

    .half-col:nth-child(odd) {
      margin-right: 3%;
    }

    #card-number, #cvv, #expiration-date, #postal-code {
      border: 1px solid black;
      padding: 8px 14px 10px;
      height: 44px;
    }
  }

}

figure {
  margin: 0;
}

img {
  max-width: 66%;
}

.skinny {
  display: block;
  padding-right: 15%;
  padding-left: 15%;
}

.pitch {

  h1 {
    margin: 27px 0 9px;

    small {
      color: red;
      display: block;
      font-size: .6em;

      &.space {
        margin-top: 36px;
      }
    }
  }

  figure + h1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  ul {
    list-style-position: inside;
    width: 77%;
  }

  p {
    margin: 10px 0;
  }

}

#upgrade {
  padding-bottom: 77px;

  p {
    margin-top: 1em;

    small {
      a {
        color: #ff1755;
      }

      display: block;
      width: 77%;
      margin: auto;
      font-style: italic;
      //color: darkblue;
      //font-style: italic;
    }

  }

  h1 {
    margin-top: 1.3em;

    & + p {
      margin-top: 0.2em;
    }
  }

  p, h1 {
    margin-left: 6%;
    width: 88%;
  }

  // make text boxes larger type, centered, black outline
  input[type=text] {
    border: 1px solid black;
    font-size: 1.1em;
    width: 88%;
    text-align: center;
    padding: 5px 10px;
  }

  // black square radio... from an unpopular answer?
  // https://stackoverflow.com/a/55925222
  input[type=radio] {
    padding: 0.5em;
    -webkit-appearance: none;
    outline: 1px solid black;
    outline-offset: 0.2em;

    // move it over a smidge
    margin-right: 9px;
    //https://stackoverflow.com/a/14762180
    margin-top: -1px;
    vertical-align: middle;

    &:checked {
      display: inline-block;
      background-color: #000;
    }
  }

  select {
    width: 55%;
    font-size: 1.1em;

  }

  button {
    background: white;
    border: 1px solid black;
    font-family: "Hoefler Text", "Georgia", "serif";
    font-size: 1.1em;
    padding: 12px 2em 9px;
    margin-top: 1em;

    &:hover {
      background: lightgray;
    }
  }


  label {
    display: block;
    font-weight: bold;
  }

  // radios are ul li label input
  ul:not(.errorlist) {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    li {
      padding: 0;
      display: flex;

      &:hover {
        background-color: lightgray;
      }

      label {
        font-size: 1em;
        display: inline-block;
        font-weight: normal;
        padding: 0.6em 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
      }
    }
  }

  .errorlist {
    display: none;
  }

  .field-error {
    input[type=text] {
      border-color: red;
      color: red;
    }
  }

  /* actually seems better to do the errors elsewhere for now
  ul.errorlist {
    list-style-type: none;
    color: red;
    font-size: 1em;
  }

  */

  .helptext {
    display: block;
    font-style: italic;
    margin: 0 10% 1em;
  }


  .locked {
    color: gray;
    pointer-events: none;

    input[type=radio] {
      outline: 1px solid gray;

      &:checked {
        background-color: gray;
      }
    }
  }

  #addons-configure {

    //https://stackoverflow.com/a/41941056
    select,
    select option {
      color: #000000;
      padding: 5px 10px;
    }

    select:invalid,
    select option[value=""] {
      color: darkred;
    }
  }

  #braintree-dropin-container-container {
    width: 100%;
    margin-top: 1.3em;
    padding: 1em 0 3em;
    background-color: lightgrey;

    & > div {
      // the actual container
      margin: auto;
      width: 88%;
      min-height: 300px;

      input {
        border: none;
      }
    }
  }

  #payment-form {
    display: none;
  }
}


.pppin4 {
  margin: auto;
  max-width: 380px;
  span {
    margin-top: -6px;
    margin-bottom: 6px;
    display: block;
  }
  [data-pp-message] {
    margin-top: 5px;
  }
}