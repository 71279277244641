body {
  text-align: center;
  background-color: #fff;
  width: 92%;
  max-width: 777px;
  font-family: Hoefler Text, Georgia, "serif";
  font-size: 1em;
  line-height: 1.3;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  margin: 0;
  font-weight: normal;
}

h1 {
  font-size: 1.7em;
}

p {
  margin: auto;
}

p small {
  font-size: .86em;
}

.sentencecase {
  text-transform: none;
}

.validation-messages a:link, .validation-messages a:visited, .validation-messages a:active {
  color: #8b0000;
}

.validation-messages a:hover {
  color: red;
}

#id_order_type {
  margin: 0;
  padding: 0;
  list-style: none;
}

#id_order_type input {
  display: none;
}

#id_order_type label {
  border: 1px solid #000;
  margin-bottom: 8px;
  padding: 10px 20px 13px;
  display: block;
}

.radio.selected {
  background-color: #d3d3d3;
}

input[type="checkbox"] {
  display: none;
}

.fancy-check {
  vertical-align: text-bottom;
  border: 1px solid #000;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.fancy-check.checked:before {
  content: "x";
  margin-left: 1px;
}

mark {
  background-color: inherit;
  color: red;
}

.form-item {
  padding-bottom: 9px;
}

.form-item .sold-out {
  text-decoration: line-through;
}

.contact-head {
  margin-bottom: 12px;
  text-decoration: underline;
}

table {
  border-collapse: collapse;
  margin: auto;
}

table th {
  text-transform: uppercase;
  font-weight: normal;
}

table td, table th {
  border: 1px solid #000;
  padding: 7px 12px 9px;
}

#paypal-button {
  width: 58%;
  margin: auto;
}

.clear {
  clear: both;
}

#payment-form #paypal-button {
  margin-bottom: 12px;
}

#payment-form > p, #payment-form > label, #payment-form .spacer {
  margin-bottom: 9px;
}

#payment-form #btcc_fields {
  width: 80%;
  margin: auto;
}

#payment-form #btcc_fields .half-col {
  float: left;
  width: 48.5%;
  margin: auto;
  display: inline;
}

#payment-form #btcc_fields .half-col:nth-child(odd) {
  margin-right: 3%;
}

#payment-form #btcc_fields #card-number, #payment-form #btcc_fields #cvv, #payment-form #btcc_fields #expiration-date, #payment-form #btcc_fields #postal-code {
  border: 1px solid #000;
  height: 44px;
  padding: 8px 14px 10px;
}

figure {
  margin: 0;
}

img {
  max-width: 66%;
}

.skinny {
  padding-left: 15%;
  padding-right: 15%;
  display: block;
}

.pitch h1 {
  margin: 27px 0 9px;
}

.pitch h1 small {
  color: red;
  font-size: .6em;
  display: block;
}

.pitch h1 small.space {
  margin-top: 36px;
}

.pitch figure + h1 {
  margin-top: 0;
  margin-bottom: 0;
}

.pitch ul {
  width: 77%;
  list-style-position: inside;
}

.pitch p {
  margin: 10px 0;
}

#upgrade {
  padding-bottom: 77px;
}

#upgrade p {
  margin-top: 1em;
}

#upgrade p small {
  width: 77%;
  margin: auto;
  font-style: italic;
  display: block;
}

#upgrade p small a {
  color: #ff1755;
}

#upgrade h1 {
  margin-top: 1.3em;
}

#upgrade h1 + p {
  margin-top: .2em;
}

#upgrade p, #upgrade h1 {
  width: 88%;
  margin-left: 6%;
}

#upgrade input[type="text"] {
  text-align: center;
  border: 1px solid #000;
  width: 88%;
  padding: 5px 10px;
  font-size: 1.1em;
}

#upgrade input[type="radio"] {
  -webkit-appearance: none;
  outline-offset: .2em;
  vertical-align: middle;
  outline: 1px solid #000;
  margin-top: -1px;
  margin-right: 9px;
  padding: .5em;
}

#upgrade input[type="radio"]:checked {
  background-color: #000;
  display: inline-block;
}

#upgrade select {
  width: 55%;
  font-size: 1.1em;
}

#upgrade button {
  background: #fff;
  border: 1px solid #000;
  margin-top: 1em;
  padding: 12px 2em 9px;
  font-family: Hoefler Text, Georgia, "serif";
  font-size: 1.1em;
}

#upgrade button:hover {
  background: #d3d3d3;
}

#upgrade label {
  font-weight: bold;
  display: block;
}

#upgrade ul:not(.errorlist) {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

#upgrade ul:not(.errorlist) li {
  padding: 0;
  display: flex;
}

#upgrade ul:not(.errorlist) li:hover {
  background-color: #d3d3d3;
}

#upgrade ul:not(.errorlist) li label {
  width: 100%;
  height: 100%;
  padding: .6em 0;
  font-size: 1em;
  font-weight: normal;
  display: inline-block;
  overflow: hidden;
}

#upgrade .errorlist {
  display: none;
}

#upgrade .field-error input[type="text"] {
  color: red;
  border-color: red;
}

#upgrade .helptext {
  margin: 0 10% 1em;
  font-style: italic;
  display: block;
}

#upgrade .locked {
  color: gray;
  pointer-events: none;
}

#upgrade .locked input[type="radio"] {
  outline: 1px solid gray;
}

#upgrade .locked input[type="radio"]:checked {
  background-color: gray;
}

#upgrade #addons-configure select, #upgrade #addons-configure select option {
  color: #000;
  padding: 5px 10px;
}

#upgrade #addons-configure select:invalid, #upgrade #addons-configure select option[value=""] {
  color: #8b0000;
}

#upgrade #braintree-dropin-container-container {
  background-color: #d3d3d3;
  width: 100%;
  margin-top: 1.3em;
  padding: 1em 0 3em;
}

#upgrade #braintree-dropin-container-container > div {
  width: 88%;
  min-height: 300px;
  margin: auto;
}

#upgrade #braintree-dropin-container-container > div input {
  border: none;
}

#upgrade #payment-form {
  display: none;
}

.pppin4 {
  max-width: 380px;
  margin: auto;
}

.pppin4 span {
  margin-top: -6px;
  margin-bottom: 6px;
  display: block;
}

.pppin4 [data-pp-message] {
  margin-top: 5px;
}

/*# sourceMappingURL=global.css.map */
